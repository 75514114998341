import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "checkbox", "toggler", "trueOption", "falseOption" ]
  static classes = [ "hidden", "active", "true", "false" ]

  checkboxTargetConnected() {
    this.updateVisualState()
    this.swapInitialVisibility()

    const self = this
    this.checkboxTarget.addEventListener("change", () => self.updateVisualState())
  }

  swapInitialVisibility() {
    this.checkboxTarget.classList.add(this.hiddenClass)
    this.togglerTarget.classList.remove(this.hiddenClass)
  }

  addVisualActivation(target) {
    target.classList.add(this.activeClass)

    const valueClass = target === this.trueOptionTarget ? this.trueClass : this.falseClass
    target.classList.add(valueClass)
  }

  removeVisualActivation(target) {
    target.classList.remove(this.activeClass)

    const valueClass = target === this.trueOptionTarget ? this.trueClass : this.falseClass
    target.classList.remove(valueClass)
  }

  setTrue() {
    if (!this.checkboxTarget.checked) {
      this.checkboxTarget.click()
    }
  }

  setFalse() {
    if (this.checkboxTarget.checked) {
      this.checkboxTarget.click()
    }
  }

  updateVisualState() {
    if (this.checkboxTarget.checked) {
      this.removeVisualActivation(this.falseOptionTarget)
      this.addVisualActivation(this.trueOptionTarget)
    } else {
      this.removeVisualActivation(this.trueOptionTarget)
      this.addVisualActivation(this.falseOptionTarget)
    }
  }
}